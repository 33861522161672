.TextViewer {
  position: relative;
  max-width: 100%;
  border-top-style: solid;
  border-top-color: rgb(99, 97, 97);
  border-top-width: 2px ;
  background-color: rgba(245, 245, 245, 0.7);  
  pointer-events: none;
  padding: 10px;
  white-space: pre-wrap;
}
